<template>
  <div :style='setupVariableColors'>
    <h1 class='py-2 text-2xl row-centered gap-x-2'>
      <span v-if='title'>{{title}}</span>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/on_air.png' class='inline-block h-8' />
    </h1>
    <div class='lg:flex lg:flex-row lg:justify-between lg:items-stretch mt-8'>
      <div v-if='!hasNoCurrentlyLiveSessions'
        class='flex-shrink-0 flex-grow-0 w-full lg:w-96'>
        <div v-for='(liveSession, index) in sessionsData'
          :key='`live-session-${index}`'
          class='session-navigation-tab'
          :class='isSelectedLiveSession(index)'>
          <session-currently-live-title 
            :live-session='liveSession'
            :tab-size='tabSize'
            :title-color='colorsInRange[3]'
            @select-live-session-room='selectLiveSessionRoom(index)' />
        </div>
      </div>
      <div class='session-detail-box fixed lg:static lg:flex-grow overflow-y-auto px-2 py-12 lg:px-6 lg:py-8 top-16 z-40 lg:z-0 rounded'
        style='background-color:var(--lightColor);'
        :class='sessionDetailsBoxClass'>
        <button 
          class='absolute block lg:hidden'
          style='top: 1rem; right: 1rem;' 
          @click='closeMobileSessionDetail'>
          <x-icon class='text-gray-500 h-5' />
        </button>
        <div v-if='hasNoCurrentlyLiveSessions'
          class='px-4 lg:px-16 py-4 lg:py-16 flex flex-col items-center justify-center'>
          <img :src='keyImageurl' class='w-40' />
          <div class='mt-16 opacity-50'>There are no currently live sessions.</div>
        </div>
        <div v-else-if='selectedLiveSessionSpeakers.length === 0'
          class='w-full px-4 lg:px-16 py-4 lg:py-16 flex flex-col items-center justify-center'>
          <img :src='keyImageurl' class='w-40' />
          <div class='mt-16 opacity-50'>There are no session details.</div>
        </div>
        <div v-else-if='doNotUseStandardSpeakerLayout'>
          <khrs-current-live-details :selected-session='selectedLiveSession' />          
        </div>
        <div v-else>
          <div v-for='(speaker, speakerIndex) in selectedLiveSessionSpeakers'
            :key='`speaker-${speakerIndex}`'
            @click='goToCurrentSession'>
            <session-currently-live-details
              :speaker='speaker'
              :speaker-index='speakerIndex' />
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'
import SessionCurrentlyLiveTitle   from '@/components/SessionCurrentlyLiveTitle.vue'
import SessionCurrentlyLiveDetails from '@/components/SessionCurrentlyLiveDetails.vue'
import KhrsCurrentLiveDetails      from '@/components/khrs-virtual/KhrsCurrentLiveDetails.vue'

export default {
  name: 'SessionsCurrentlyLive',
  components: {
    XIcon,
    SessionCurrentlyLiveTitle,
    SessionCurrentlyLiveDetails,
    KhrsCurrentLiveDetails,
  },
  props: [
    'title',
    'tabSize',
  ],
  data () {
    return {
      selectedLiveSessionIndex: 0,
      showMobileDetail: false,
    }
  },
  computed: {
    ...mapState('sessions', [
      'liveSessions',
      'currentSessionId',
    ]),
    ...mapGetters('events', [
      'liveSessionsComponentDemoData',
      'eventImageS3Path',
      'showingEventId',
      'colorsInRange',
    ]),
    sessionsData () {
      if (this.liveSessions.length > 0) {
        return this.liveSessions
      } else if (this.liveSessionsComponentDemoData) {
        return this.liveSessionsComponentDemoData
      }  else {
        return []
      }
    },
    keyImageurl () {
      return (this.eventImageS3Path) ? `${this.eventImageS3Path}/conference_key_image.png` : 'https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/remedi_logo.png'
    },
    hasNoCurrentlyLiveSessions () {
      return !this.sessionsData.some((liveSession) => liveSession.currentSession)
    },
    selectedLiveSession () {
      return this.sessionsData[this.selectedLiveSessionIndex].currentSession
    },
    selectedLiveSessionSpeakers () {
      return this.sessionsData[this.selectedLiveSessionIndex] && this.selectedLiveSession ? this.selectedLiveSession.listOfSpeakers.filter(speaker => speaker.role.toLowerCase() === 'speaker' || speaker.role.toLowerCase() === 'panelist' || speaker.role.toLowerCase() === 'panel' || speaker.role.toLowerCase() === 'presenter') : []
    },
    selectedLiveSessionId () {
      return this.selectedLiveSession ? this.selectedLiveSession.id : -1
    },
    sessionDetailsBoxClass () {
      return this.showMobileDetail ? 'block' : 'hidden'
    },
    setupVariableColors () {
      return {
       '--lightestColor': this.colorsInRange[0],
       '--lightColor': this.colorsInRange[1],
       '--inputColor': this.colorsInRange[2],
       '--darkColor': this.colorsInRange[3], 
      }
    },
    doNotUseStandardSpeakerLayout () {
      return this.showingEventId === 11
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getLiveSessions',
      'getCurrentSessionId',
    ]),
    isSelectedLiveSession (index) {
      return (this.selectedLiveSessionIndex === index) ? 'selected' : ''
    },
    selectLiveSessionRoom (index) {
      this.selectedLiveSessionIndex = index
      this.openMobileSessionDetail()
    },
    goToCurrentSession () {
      this.getCurrentSessionId(this.selectedLiveSessionId).then(() => {
        if (this.currentSessionId) {
          if (this.selectedLiveSessionId === this.currentSessionId) {
            this.$router.push({name: 'Session', query: {session_id: this.selectedLiveSessionId}}).catch(() => {})
          } else {
            this.$confirm('This is not the current session for this room. Would you like to see the current session?', {
              confirmButtonText: 'Go to Current',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(() => {
              this.$router.push({name: 'Session', query: {session_id: this.currentSessionId}}).catch(() => {})
            }).catch(() => {})
          }
        } else {
          this.$alert('There are no sessions in progress', {
            confirmButtonText: 'Done',
            type: 'warning'
          })
        }
      })
    },
    closeMobileSessionDetail () {
      this.showMobileDetail = false
    },
    openMobileSessionDetail () {
      this.showMobileDetail = true
    }
  },
  mounted () {
    this.getLiveSessions()
  }
}
</script>

<style lang='scss' scoped>
  .session-detail-box {
    height: calc(100vh - 4rem);
    width: 100%;
  }

  @media (min-width: 1024px) {
    .session-detail-box {
      display: block;
      height: auto;
      width: auto;
    }
  }

  .session-navigation-tab {
    @apply border-l-4 border-transparent;
    background-color: var(--lightestColor); //#fdf9fb;
    h2 {
      @apply font-thin;
    }
    h3 {
      @apply font-normal;
    }
    button {
      @apply opacity-40;
    }
  }

  .session-navigation-tab.selected {
    border-left-color: var(--inputColor); // #e393c1;
    background-color: var(--lightColor); //#F6E7F1;
    h2 {
      @apply font-normal;
    }
    h3 {
      @apply font-medium;
    }
    button {
      @apply opacity-100;
    }
  }

</style>
