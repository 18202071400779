<template>
  <div>
    <h3 class='uppercase font-semibold'>{{role}}s</h3>
    <table class='w-full text-sm lg:text-base'>
      <tr v-for='speaker in speakers'
        :key='`selected-session-speaker-${role}-${speaker.id}`'
        class='border-t'
        :class='focusedSpeakerClass(speaker.id)'>
        <td class='text-left w-1/3'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image.png' class='standard-transition opacity-80 h-4 hidden'>
          {{speaker.name}}
        </td>
        <td class='text-left text-gray-500'>{{speaker.affiliation}}</td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: 'KhrsCurrentLiveDetailsSpeakersByCategory',
  props: [
    'speakers',
    'role',
    'focusedSpeakerId',
  ],
  methods: {
    focusedSpeakerClass (speakerId) {
      return (this.role.toLowerCase() !== 'moderator' && 
              this.role.toLowerCase() !== 'chair' && 
              this.focusedSpeakerId === speakerId) 
             ? 'focused-speaker' : ''
    },
  },
}
</script>

<style lang='scss' scoped>
  .focused-speaker td {
    color: var(--eventMainColor);
  }
  .focused-speaker td img {
    @apply inline-block;
  }
</style>
