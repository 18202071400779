<template>
  <div class=''>
    <div class='flex flex-col lg:flex-row justify-between items-start'>
      <div>
        <h1 class='text-2xl font-semibold'>{{selectedSession.description}}</h1>
        <h2 class='uppercase' :style='themeTextColorStyle'>{{selectedSession.title}}</h2> 
        <h4 class='uppercase text-gray-600 text-sm'>{{sessionTimeString}}</h4>
      </div>
      <button
        class='mt-4 lg:mt-0 hover:shadow-md'
        @click.stop='goToCurrentSession'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/play_button.svg' class='flex-grow-0 flex-shrink-0 h-8' />
      </button>
    </div>
    <div class='mt-8 flex flex-col lg:flex-row justify-start items-start gap-x-8'>
      <img :src='currentFocusSpeaker.imageUrl' class='flex-grow-0 flex-shrink-0 w-1/2 lg:w-1/4 p-2 bg-white rounded mx-auto mb-4 lg:m-0' />
      <div class='w-full lg:w-3/4'>
        <khrs-current-live-details-speakers-by-category v-for='speakerRole in speakerRolesWithSpeakers'
          :key='`speaker-role-table-${speakerRole}`'
          :role='speakerRole'
          :speakers='speakersByRole(speakerRole)' 
          :focused-speaker-id='currentFocusSpeaker.id'
          class='mb-6' />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import KhrsCurrentLiveDetailsSpeakersByCategory from '@/components/khrs-virtual/KhrsCurrentLiveDetailsSpeakersByCategory.vue'
import dayjs from 'dayjs'

export default {
  name: 'KhrsCurrentLiveDetails',
  components: {
    KhrsCurrentLiveDetailsSpeakersByCategory,
  },
  props: [
    'selectedSession',
  ],
  data () {
    return {
      speakerRoles: [
        'chair',
        'moderator',
        'presenter',
        'speaker',
        'panelist',
        'panel',
        'judge',
      ]
    }
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
    sessionLengthInMinutes () {
      let start = dayjs(this.selectedSession.scheduledStartTime)
      let end   = dayjs(this.selectedSession.scheduledEndTime)
      return end.diff(start, 'minute')
    },
    focusSpeakers () { // only include presetner, speakers, and panelists
      return [...this.speakersByRole('presenter'),
              ...this.speakersByRole('panelist'),
              ...this.speakersByRole('panel'),
              ...this.speakersByRole('speaker')]
    },
    currentFocusSpeaker () {
      return this.focusSpeakers[this.speakerFocusIndex]
    },
    speakerFocusIndex () {
      let start = dayjs(this.selectedSession.scheduledStartTime)
      if (dayjs().isBefore(start, 'minute')) {
        return 0
      }
      return Math.min(Math.floor(dayjs().diff(start, 'minute') / this.speakerFocusForMinutes), this.speakersFocusCount-1)
    },
    speakersFocusCount () {
      return this.focusSpeakers.length
    },
    speakerRolesWithSpeakers () {
      return this.speakerRoles.filter(role => this.speakersByRole(role).length)
    },
    speakerFocusForMinutes () {
      return this.sessionLengthInMinutes / this.speakersFocusCount
    },
    sessionTimeString () {
      return `${dayjs(this.selectedSession.scheduledStartTime).format('h:mm')} - ${dayjs(this.selectedSession.scheduledEndTime).format('h:mm')}`
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
    ]),
    speakersByRole (roleString) {
      return this.selectedSession.listOfSpeakers.filter(speaker => speaker.role.toLowerCase() == roleString)
    },
    goToCurrentSession () {
      this.getCurrentSessionId(this.selectedSession.id).then((currentSessionId) => {
        if (currentSessionId) {
          if (this.selectedSession.id === currentSessionId) {
            this.$router.push({name: 'Session', query: {session_id: this.selectedSession.id}}).catch(() => {})
          } else {
            this.$confirm('This is not the current session for this room. Would you like to see the current session?', {
              confirmButtonText: 'Go to Current',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(() => {
              this.$router.push({name: 'Session', query: {session_id: currentSessionId}}).catch(() => {})
            }).catch(() => {})
          }
        } else {
          this.$alert('There are no sessions in progress', {
            confirmButtonText: 'Done',
            type: 'warning'
          })
        }
      })
    },
  }
}
</script>
