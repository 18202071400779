<template>
  <div v-if='smallSizeTab'
    class='flex flex-row justify-start items-center relative mb-1 px-4 cursor-pointer'
    :class='sizeBasedPadding'
    @click='selectLiveSessionRoom'>
    <div class='w-20 flex-grow-0 flex-shrink-0'>
      <h4 class='text-normal uppercase' :style='`color: ${titleColor};`'>Room {{roomNameString(liveSession.roomName)}}</h4>
    </div>
    <div v-if='liveSession.currentSession'
       style='width: calc(100% - 8rem);'>
      <h3 class='text-sm uppercase text-gray-800 truncate'>
        {{liveSession.currentSession.title}}
      </h3>
      <button v-if='liveSession.currentSession'
        class='absolute hover:shadow-md'
        :class='sizeBasedPosition'
        @click.stop='goToCurrentSession'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/play_button.svg' class='h-full' :style='eventMainThemeColorBg' />
      </button>
    </div>
    <h3 v-else class='font-medium text-xs uppercase text-gray-400'>There are no sessions in progress.</h3>

  </div>
  <div v-else
    class='flex flex-row justify-start relative mb-2 px-4 cursor-pointer'
    :class='sizeBasedPadding'
    @click='selectLiveSessionRoom'>
    <div class='w-20 flex-grow-0 flex-shrink-0'>
      <h4 class='text-normal uppercase' :style='`color: ${titleColor};`'>Room</h4>
      <h2 class='text-gray-900'
        :class='roomTextSize'>
        {{roomNameString(liveSession.roomName)}}
      </h2>
    </div>
    <div v-if='liveSession.currentSession'
      class='flex-grow'>
      <h3>{{sessionTimeString(liveSession.currentSession.scheduledStartTime, liveSession.currentSession.scheduledEndTime)}}</h3>
      <h3 class='text-sm uppercase text-gray-800'>
        {{liveSession.currentSession.title}}
      </h3>
      <v-clamp 
        class='whitespace-pre-line font-normal text-xs text-gray-600 mt-1'
        :max-lines='2'>
        {{liveSession.currentSession.description}}
      </v-clamp>
    </div>
    <h4 v-else class='font-medium text-xs uppercase text-gray-400 mt-1'>There are no sessions in progress.</h4>
    <button v-if='liveSession.currentSession'
      class='absolute hover:shadow-md'
      :class='sizeBasedPosition'
      @click.stop='goToCurrentSession'>
      <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/play_button.svg' class='h-full' :style='eventMainThemeColorBg' />
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs  from 'dayjs'
import VClamp from 'vue-clamp'

export default {
  name: 'SessionCurrentlyLiveTitle',
  components: {
    VClamp,
  },
  props: [
    'liveSession',
    'tabSize',
    'titleColor',
  ],
  computed: {
    ...mapState('sessions', [
      'currentSessionId',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    eventMainThemeColorBg () {
      return `color: ${this.eventMainThemeColor};`
    },
    selectedLiveSessionId () {
      return this.liveSession.currentSession ? this.liveSession.currentSession.id : -1
    },
    smallSizeTab () {
      return this.tabSize === 'sm'
    },
    sizeBasedPadding () {
      return this.smallSizeTab ? ' pt-2 pb-2' : 'py-6'
    },
    sizeBasedPosition () {
      return this.smallSizeTab ? ' top-2 right-2 h-6' :  'top-3 right-3 h-8'
    },
    roomTextSize () {
      return this.smallSizeTab ? 'text-xl leading-4' : 'text-2xl leading-6'
    }
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
    ]),
    roomNameString (roomName) {
      return (roomName) ? roomName.split('room')[1].trim() : ''
    },
    selectLiveSessionRoom () {
      this.$emit('select-live-session-room')
    },
    sessionTimeString (startTime, endTime) {
      return `${dayjs(startTime).format('HH:mm')} ~ ${dayjs(endTime).format('HH:mm')}`
    },
    goToCurrentSession () {
      this.getCurrentSessionId(this.selectedLiveSessionId).then(() => {
        if (this.currentSessionId) {
          if (this.selectedLiveSessionId === this.currentSessionId) {
            this.$router.push({name: 'Session', query: {session_id: this.selectedLiveSessionId}}).catch(() => {})
          } else {
            this.$confirm('This is not the current session for this room. Would you like to see the current session?', {
              confirmButtonText: 'Go to Current',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(() => {
              this.$router.push({name: 'Session', query: {session_id: this.currentSessionId}}).catch(() => {})
            }).catch(() => {})
          }
        } else {
          this.$alert('There are no sessions in progress', {
            confirmButtonText: 'Done',
            type: 'warning'
          })
        }
      })
    },
  },
}
</script>
