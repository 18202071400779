<template>
  <div 
    class='mb-8 flex items-center border border-transparent hover:border-pink-300 hover:shadow-md p-2 rounded-md cursor-pointer'
    :class='photoDescriptionOrder'>
    <img 
      class='h-32 bg-white object-cover'
      style='max-width: 8rem;'
      :src='speaker.imageUrl' />
    <div>
      <h3 class='text-gray-800 mb-2 text-sm'>
        {{ speaker.topic }}
      </h3>
      <h4 class='flex flex-row justify-start gap-x-8 items-center text-xs mb-1'
        style='color: #EA475D;'>
        {{speaker.name}}
      </h4>
      <h4 class='text-xs' style='color: #939393;'>{{speaker.affiliation}} {{speaker.intro}}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SessionCurrentlyLiveDetails',
  props: [
    'speaker', 
    'speakerIndex',
  ],
  computed: {
    photoDescriptionOrder () {
      return (this.speakerIndex % 2 === 0) ? 'flex-row pr-4 lg:pr-8 justify-start gap-x-16' : 'flex-row-reverse pl-4 lg:pl-8 justify-between gap-x-16'
    },
  }
}
</script>
